
import axiosClient from '@/lib/rest/axiosClient';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getConfigEnv, randomAssetNameForDemo } from '@/utils/helpers';
import { Debounce } from 'vue-debounce-decorator';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as RecordRTC from 'recordrtc';
import { mixins } from 'vue-class-component';
import GptMixin from '@/components/gpt/GptMixin';
import gptModule from '@/store/modules/gptModule';
import workflowModule from '@/store/modules/workflowModule';
import uniqid from 'uniqid';
import accountModule from '@/store/modules/accountModule';
import axios from 'axios';

@Component({
  components: {
    Dropdown: () => getComponent('common/Dropdown'),
  },
})
export default class TasqAIAudioEntry extends mixins(GptMixin) {
  selectedAudioSegment: any = null;

  audioSegments: any = [];

  randomAssetNameForDemo = randomAssetNameForDemo;

  async mounted() {
    await this.getRealtimeToken();
    this.selectedAudioTab = '';
    this.selectedAudioSegment = this.audioSegments[0];
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    const wells = assetsModule.enabledWells.map((well) => {
      return well.text;
    });

    const pads = assetsModule.enabledPads.map((pad) => {
      return pad.text;
    });
    return [...wells, ...pads];
  }

  updateTab(key) {
    this.selectedAudioTab = key;
    this.selectedAudioSegment = this.audioSegments.find((s) => s.asset === key);
  }

  getActionValue(val) {
    let action = val;
    if (action) {
      action = action.toLowerCase();
    }
    if (val.includes('waiting on')) {
      return 'Waiting on';
    }
    if (val.includes('Last action')) {
      return 'Downtime';
    }
    if (val.includes('Todo item')) {
      return 'Todo item';
    }
    return val;
  }

  updateModel(actionIndex, eventIndex, event) {
    // if( !event.target.innerText)return
    this.audioSegments[0].actions[actionIndex].events[eventIndex].reason =
      event.target.innerText;
    this.setCursorPosition(event);
  }

  setCursorPosition(event) {
    const range = document.createRange();
    const sel = window.getSelection();
    range.setStart(event.target.childNodes[0], event.target.innerText.length);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }

  // AUDIO CODE

  isRecording = false;
  transcript = '';
  recognition: any = null;
  selectedAudioTab = '';
  showAudioSegment = true;
  isSpeaking = false;

  websocket: any = null;
  recorder: any = null;

  authToken: any = '';

  async getRealtimeToken() {
    try {
      const { data } = await axiosClient.get(`/tasq-ai/audio-token`);
      this.authToken = data.token;
    } catch (error) {
      console.error('Error fetching realtime token:', error);
    }
  }

  async startTranscription(event: Event) {
    event.preventDefault();

    const token = this.authToken;
    const words = [];

    try {
      this.setupWebSocket(token, words);
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          sampleRate: { ideal: 48000 },
          channelCount: { ideal: 2 },
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });
      this.setupRecorder(stream);
    } catch (error) {
      console.error('Error starting transcription:', error);
      this.cleanupResources();
    }
  }

  setupWebSocket(token: string, words: string[]) {
    const texts = { 0: this.transcript };

    this.websocket = new WebSocket(
      `wss://api.assemblyai.com/v2/realtime/ws?sample_rate=48000&token=${token}&word_boost=${JSON.stringify(
        words
      )}`
    );

    this.websocket.onopen = () => {
      console.log('WebSocket connection established');
      this.isRecording = true;
    };

    this.websocket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.message_type === 'PartialTranscript') {
        texts[response.audio_start + 1] = response.text;
        this.transcript = Object.keys(texts)
          .sort((a, b) => Number(a) - Number(b))
          .map((key) => texts[key])
          .join(' ');
      }
    };

    this.websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.cleanupResources();
    };

    this.websocket.onclose = (event) => {
      console.log(`WebSocket connection closed: ${event.code} ${event.reason}`);
      this.websocket = null;
    };
  }

  setupRecorder(stream: MediaStream) {
    // Integrated SpeechRecognition into the recorder logic
    this.recorder = new RecordRTC(stream, {
      type: 'audio',
      mimeType: 'audio/webm;codecs=pcm',
      recorderType: RecordRTC.StereoAudioRecorder,
      timeSlice: 100, // Reduce timeSlice to send smaller chunks
      desiredSampRate: 48000,
      numberOfAudioChannels: 1,
      bufferSize: 4096,
      audioBitsPerSecond: 320000,
      ondataavailable: async (blob: Blob) => {
        if (!this.websocket || !this.isRecording) return;
        const buffer = await blob.arrayBuffer();
        if (this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.send(buffer);
        }
      },
      onerror: (error) => {
        console.error('Recorder error:', error);
        this.cleanupResources();
      },
    });
    this.recorder.startRecording();
  }

  async endTranscription(event: any) {
    if (event) event.preventDefault();
    this.isRecording = false;
    this.cleanupResources();
  }

  cleanupResources() {
    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        if (this.recorder) {
          // Check if `this.recorder` is still not null
          this.recorder
            .getTracks()
            .forEach((track: MediaStreamTrack) => track.stop());
          this.recorder = null;
        }
      });
    }
  }

  stopRecording() {
    if (!this.isRecording) return;

    this.isRecording = false;
    this.recognition!.stop();
  }

  closePopup() {
    this.cleanupResources();
    this.$emit('close');
  }

  get reassignUsers() {
    return accountModule.reassignmentList
      .map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  isLoadingSegments = false;

  async submitAudioEntry() {
    const results = this.audioSegments
      .map((asset) => {
        const wellName = asset.assetModel[0];
        return asset.actions
          .filter((action) => action.selected)
          .map((action) => {
            return action.details.map((detail) => {
              return {
                wellName,
                actionType: action.action,
                dueDate: detail.due_date,
                comment: detail.details,
              };
            });
          })
          .flat();
      })
      .flat();

    results.forEach(async (result) => {
      if (
        result.actionType.includes('waiting on') ||
        result.actionType.includes('Last action')
      ) {
        const metaData = {
          useAll: false,
          body: result.comment,
          ...(result.actionType.includes('waiting on') && {
            type: 'Waiting on',
          }),
          ...(result.actionType.includes('waiting on') && {
            end_date: result.dueDate,
          }),
          wellMetaData: {
            ...(this.operatorName !== 'demo' && {
              operator_name: this.operatorDetails['operatorName'],
            }),
            ...(this.operatorName !== 'demo' && {
              operator_id: this.operatorDetails['operatorID'],
            }),
            nodeid: result.wellName,
            level: 'well',
          },
          source_type: 'audio_entry',
        };

        const payload = {
          query: '',
          stream: false,
          ...metaData,
        };

        await gptModule.postGptComment(payload);
      } else if (result.actionType.includes('Todo item')) {
        console.log(result);
        console.log('to do');

        const assignees = [];

        this.reassignUsers.forEach((user) => {
          const atMention = '@' + user.text;
          if (result.comment.includes(atMention)) {
            assignees.push(user.value);
          }
        });

        const item = {
          checked: false,
          text: result.comment,
          dueDate: result.dueDate,
          dateType: 'None',
          id: await uniqid(),
          isHovering: false,
          updatedAt: new Date().getTime(),
          assignees: assignees,
        };

        workflowModule.postSingleTodoForNodelist({
          nodeList: [result.wellName],
          activeList: [item],
        });
      }
    });

    this.$tasqAlert({
      title: 'Success',
      message: 'Audio Entry Submitted',
      type: 'success',
    });
    this.endTranscription(null);
    this.$emit('close');
  }

  prevTranscript = '';

  @Debounce(2000)
  @Watch('transcript')
  async onUpdateTranscript(val, old) {
    console.log(val);
    console.log(val === old);
    if (val === old) return;
    if (
      this.transcript.trim() === this.prevTranscript.trim() ||
      this.transcript.length < 3
    )
      return;
    this.prevTranscript = this.transcript;
    const url = window.location.href;
    let assets = []
    if(getConfigEnv('DEMO_MODE')  || url.includes('demo')){
        assets = ['Well W', 'Well M', 'Well N', 'Well O', 'Well P', 'Well Q', 'Well R', 'Well S', 'Well T', 'Well U', 'Well V', 'Well B', 'Well X', 'Well Y', 'Well Z', 'Well A', 'Well C', 'Well D', 'Well E', 'Well F', 'Well G', 'Well H', 'Well I', 'Well J', 'Well K', 'Well L']

    }
    this.isLoadingSegments = true;
    const { data } = await axiosClient.post('/tasq-ai/audio-segments', {
      operator_name: this.operatorDetails['operatorName'],
      query: this.transcript,
      asset_names: assets,
      usernames: this.reassignUsers.map((user) => user.text),
    });
    const { response } = data;
    if (response && response.length) {
      const allSegments = response.map((segment) => {
        segment.actions = segment.actions.map((s) => {
          return {
            ...s,
            selected: true,
          };
        });
        return {
          ...segment,
          assetModel: [segment.asset],
          selected: true,
        };
      });
      console.log(allSegments);
      console.log(allSegments.map((segment) => segment.asset));

      this.audioSegments = allSegments.filter((segment) => {
        return (
          segment?.asset && this.enabledWellsMapping.includes(segment.asset)
        );
      });

      if (this.audioSegments.length > 0) {
        this.selectedAudioSegment = this.audioSegments[0];
        this.selectedAudioTab = this.selectedAudioSegment.asset;
      }
    }
    this.isLoadingSegments = false;
  }
}
// waiting on status was appliend on crawford 1h
